import * as React from "react"
import styled from "styled-components"
import { FC, useState } from "react"
import * as colors from "../../constants/colors"
import { linkBlue } from "../../constants/colors"
import {
  RequestQuoteFormModal,
  RequestQuoteFormStateType,
} from "social-supermarket-components"
import * as tracker from "../../tracking/tracker"
import { getConfig } from "../../../config/index"
import { ProductType, VariantType } from "social-supermarket-model"

const Container = styled.div``
const Title = styled.div`
  font-weight: bold;
`
const RequestQuoteSection = styled.div`
  margin-bottom: 20px;
  border: 1px solid ${colors.borderGrey};
  padding: 10px;
  a {
    text-transform: none !important;
    color: ${linkBlue};
    cursor: pointer;
  }
`

interface Props {
  product: ProductType
  variant: VariantType
}

const RequestQuoteBox: FC<Props> = ({ product, variant }) => {
  const [showQuoteModal, setShowQuoteModal] = useState<boolean>(false)

  const onQuoteSuccessGeneral = (state: RequestQuoteFormStateType) => {
    const price = variant ? variant.price : product.price
    tracker.trackConversion("request_quote_general", state.quantity * price)
  }

  return (
    <div>
      <RequestQuoteSection>
        <Title>Request a quote</Title>
        Ordering in bulk for your company?{" "}
        <a onClick={() => setShowQuoteModal(true)}>Request a quote</a> and a member of our team will
        reach out to you directly.
      </RequestQuoteSection>
      <RequestQuoteFormModal
        baseUrl={getConfig().baseUrl}
        product={product}
        variant={variant}
        onSuccess={onQuoteSuccessGeneral}
        mqlContactSource={"Quote Request - General"}
        formName={"RequestQuoteForm"}
        show={showQuoteModal}
        onClose={() => setShowQuoteModal(false)}
      />
    </div>
  )
}

export default RequestQuoteBox
